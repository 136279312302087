// Vue section
import Vue from 'vue/dist/vue.esm';
import VueCompositionAPI from '@vue/composition-api'
import TurbolinksAdapter from 'vue-turbolinks'
import VueResource from 'vue-resource'
import jsQR from "jsqr";
import VueQRCodeComponent from 'vue-qrcode-component'
import ActionCable from 'actioncable'
import VeeValidate from 'vee-validate'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import VueClipboard from 'vue-clipboard2'
import vuetwemoji from 'vue-twemoji'
import Vue2Filters from 'vue2-filters'
import VueBarcode from 'vue-barcode'
import ApolloClient from 'apollo-boost'
import VueApollo from 'vue-apollo'
import ImageUploader from 'vue-image-upload-resize'
import * as VueGoogleMaps from 'vue2-google-maps'
import { PiniaVuePlugin, createPinia } from 'pinia'
import VueMask from 'v-mask'
// Custom components
import copyToClipboard from 'components/utils/copy_to_clipboard'
import date from 'components/utils/date'
import waiter from 'components/utils/waiter'
import selectAccount from 'components/select_account'
import VueQrcodeReader from "vue-qrcode-reader"
import clientQrScanner from 'components/client_qr_scanner'
import profileCablePlug from 'components/profile_cable_plug'
import balance from 'components/balance'
import balanceRewards from 'components/balance_rewards'
import dobPicker from 'components/dob_picker'
import autoPrinter from 'components/auto_print'
import progressBar from 'components/progress_bar'
import merchantProgressBar from 'components/merchant_progress_bar'
import unverifiedProgressBar from 'components/unverified_progress_bar'
import notifications from 'components/notifications'
import payFields from 'components/pay_fields'
import payByCard from 'components/pay_by_card'
import freePaysNew from 'components/free_pays/new'
import freePaysNewWithPhone from 'components/free_pays/new_with_phone'
import freePaysNewWithEmail from 'components/free_pays/new_with_email'
import unverifiedFromFreePay from 'components/free_pays/unverified_from_free_pay'
import freeScanner from 'components/free_pays/scanner'
import loyaltyProgress from 'components/loyalty_progress'
import loyaltyReward from 'components/loyalty_reward'
import skipThisStepWrapper from 'components/skip_this_step_wrapper'
import reports from 'components/reports'
import withdrawBalance from 'components/withdraw_balance'
import withdrawRewardBalance from 'components/withdraw_reward_balance'
import addMoney from 'components/add_money'
import kba from 'components/kba'
import stripeBalance from 'components/merchant/stripe_balance'
import chargeAchDirect from 'components/merchant/ach/direct_charge'
import chargeGenericCard from 'components/merchant/charge_generic_card'
import adyenTosViewer from 'components/merchant/adyen_tos_viewer'
import disputesBadge from 'components/merchant/badges/disputes'
import checkByPhoto from 'components/merchant/check_by_photo'
import checkByDoublePhoto from 'components/merchant/check_by_double_photo'
import newPaymentEvent from 'components/merchant/new_payment_event'
import subAccDownloadApp from 'components/merchant/sub_acc_download_app'
import chargeCardOnFile from 'components/merchant/charge_card_on_file'
import todayStat from 'components/merchant/today_stat'
import homeStat from 'components/merchant/home_stat'
import showCheckImages from 'components/merchant/show_check_images'
import showSignature from 'components/merchant/show_signature'
import showBadCheckImages from 'components/merchant/show_bad_check_images'
import manualCharge from 'components/merchant/manual_charge/manual_charge'
import directCharge from 'components/merchant/manual_charge/direct_charge'
import genericDirectCharge from 'components/merchant/generic_cards/direct_charge'
import genericRemoteCharge from 'components/merchant/generic_cards/remote_charge'
import terminalCharge from 'components/merchant/manual_charge/terminal_charge'
import requestChangeAmount from 'components/merchant/request_change_amount'
import resendSms from 'components/merchant/manual_charge/resend_sms'
import overrideButton from 'components/merchant/manual_charge/override_button'
import sendReceiptButton from 'components/merchant/manual_charge/send_receipt_button'
import cancelPendingTransaction from 'components/merchant/cancel_pending_transaction'
import reverseButton from 'components/merchant/reverse_button'
import voidCheckButton from 'components/merchant/void_check_button'
import sunAccountPicker from 'components/merchant/sub_account_picker'
import addItems from 'components/merchant/add_items'
import paypalOnboard from 'components/merchant/paypal/onboard'
import createCardOnFile from 'components/merchant/create_card_on_file'
import banner from 'components/client/banner'
import sendCheck from 'components/client/send_check'
import checkByImage from 'components/client/check_by_image'
import feedbackHub from 'components/client/feedback_hub'
import linkOrder from 'components/client/link_order/base'
import verifyPhone from 'components/client_unverified/verify_phone'
import verifyEmail from 'components/client_unverified/verify_email'
import setupUnverifiedProfile from 'components/client_unverified/setup_profile'
import setupUnverifiedProfileByButton from 'components/client_unverified/setup_profile_by_button'
import unverifiedProfileSwitchToIosApp from 'components/switch_to_ios_app'
import myQrs from 'components/client/my_qrs'
import adminMasterReportDownload from 'components/admin/master_report_download'
import adminRoutingInput from 'components/admin/routing_input'
import adminCheckImageUploader from 'components/admin/check_image_uploader'
import adminCheckImagePreview from 'components/admin/check_image_preview'
import reAssignQR from 'components/merchant/assign_qr/button'
import reAssignQrScanner from 'components/merchant/assign_qr/scanner'
import reAssignQrBase from 'components/merchant/assign_qr/base'
import merchantLinkOrder from 'components/merchant/link_order'
import merchantProofOfService from 'components/merchant/proof_of_service'
import merchantLinkCheckCreate from 'components/merchant/link_check/create'
import merchantLinkCheckChangeState from 'components/merchant/link_check/change_state'

import billSplitMain from 'components/client/bill_split/main'
import billSplitNew from 'components/client/bill_split/new'
import billSplitActive from 'components/client/bill_split/active'
import payBillSplit from 'components/client/bill_split/pay'

import giftCardPaymentNew from 'components/client/gift_card/new'
import addEbtCard from 'components/client/add_ebt_card'
import addCreditCard from 'components/client/add_credit_card'
import ebtBalance from 'components/client/ebt_balance'
import manualPayment from 'components/client/manual_payment/manual_payment'

import deviseLinkWrapper from 'components/devise_links_wrapper'

import policyUrl from 'components/settings/policy_url'
import sendBillNote from 'components/settings/send_bill_note'
import notificationSettings from 'components/settings/notifications'
import feedbackHubSettings from 'components/settings/feedback_hub'
import requireSecuredCheck from 'components/settings/require_secured_check'
import subaccHistoryLimit from 'components/settings/subacc_history_limit'
import autoReload from 'components/settings/auto_reload'
import tipSwitch from 'components/settings/tip_switch'
import tipSwitchForPos from 'components/settings/tip_switch_for_pos'
import tipPerSub from 'components/settings/tip_per_sub'
import tipValues from 'components/settings/tip_values'
import subAccAccessToCof from 'components/settings/sub_acc_access_to_cof'
import allowManageSubaccounts from 'components/settings/allow_manage_subaccounts'
import updateGenericFlow from 'components/settings/generic_flow'
import updateGatewaySwitch from 'components/settings/gateway'
import updateRequireInvoiceField from 'components/settings/require_invoice_field_switch'
import updateRequireInvoiceConfirmation from 'components/settings/require_invoice_confirmation'
import refundVoidSwitch from 'components/settings/refund_void_switch'
import allowEmailReceipts from 'components/settings/allow_email_receipts'
import allowSearchThroughAllTransactions from 'components/settings/allow_search_all_transactions'
import showSignaturePadSwitch from 'components/settings/show_signature_pad'
import payByBankSetting from 'components/settings/pay_by_bank_settings'
import terminalPaySetting from 'components/settings/terminal'
import terminalEnabledSetting from 'components/settings/terminal_enabled'
import withdrawSwitch from 'components/settings/withdraw_switch'
import loyalty from 'components/settings/loyalty'
import tax from 'components/settings/tax'
import showAtDash from 'components/settings/show_at_dash'
import radar from 'components/settings/radar'
import paymentFeeSettings from 'components/settings/payment_fee'
import blindTransactionSettings from 'components/settings/blind_transaction_settings'
import doingBusinessAs from 'components/settings/doing_business_as'
import emojify from 'components/emoji'
import genericProgressBar from 'components/progress_bar_generic'

import pinpadProxy from 'components/pinpad_proxy'
import fundingSourcePicker from 'components/client/funding_source_picker'

import externalPayment from 'components/client/external_payment/base'

// Manager stuff
import managerMerchants from 'components/manager/merchants'

// 3d party components
import moment from 'moment'
import VueSignaturePad from 'vue-signature-pad';
import VueTelInput from 'vue-tel-input'
import VueGoodTablePlugin from 'vue-good-table';
import VueI18n from 'vue-i18n'

import 'vue-good-table/dist/vue-good-table.css'
// We do not use TurbolinksAdapter for now because we block turbolinks caching 
// and all vue components re-renderering on each page load ny link click or by
// brawser Back/Forward buttons. 
//
// Vue.use(TurbolinksAdapter)
VueClipboard.config.autoSetContainer = true

Vue.use(VueSignaturePad);
Vue.use(Vue2Filters)
Vue.use(vuetwemoji, [])
Vue.use(vueAwesomeCountdown, 'vac')
Vue.use(VueQrcodeReader)
Vue.use(VueBarcode)
Vue.use(VueResource)
Vue.use(VueClipboard)
Vue.use(VueTelInput, {defaultCountry: "US"})
Vue.use(VeeValidate,
  {
    classes: true,
    classNames: {
      valid: 'valid',
      invalid: 'invalid'
    }
  });
Vue.use(VueGoodTablePlugin);
Vue.use(VueApollo)
Vue.use(ImageUploader)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.GOOGLE_MAPS_API_KEY
  },
})
Vue.use(VueI18n)
Vue.use(VueCompositionAPI)
Vue.use(PiniaVuePlugin);
Vue.use(VueMask);
const pinia = createPinia();
// Vue.use(pinia)

Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

import en from '../locales/en'
import es from '../locales/es'

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
    es: es
  }
})

const apolloClient = new ApolloClient({
  uri: process.env.VUE_APP_GRAPHQL_URL
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})


// Congigure ActionCable connection
const cable = ActionCable.createConsumer()
Vue.prototype.$cable = cable
// Configure Events bus
const bus = new Vue()
Vue.prototype.$bus = bus

Vue.prototype.$moment = moment
Vue.prototype.$webkit = window.webkit;
Vue.prototype.$android = window.Android;
Vue.prototype.$jsQR = jsQR
Vue.prototype.$apolloProvider = apolloProvider
Vue.mixin({
  data: function () {
    return {
    }
  },
  methods: {
    notifyError(message) {
      M.toast({html: message, classes: 'red darken-1'})
    },
    notifySuccess(message) {
      M.toast({html: message, classes: 'emerald-back'})
    },
    errorHandler(error) {
      if(error.status == 422) {
        this.notifyError(error.body.error_messages[0])
      } else if(error.status == 429) {
        this.notifyError('Too many requests. Please try again later')
      } else if(error.status == 429) {
        this.notifyError('Too many requests. Please try again later')
      } else {
        this.notifyError('Something went wrong')
      }
    },
    gqlErrorHandler(error) {
      if(error.graphQLErrors.length) {
        this.notifyError(error.graphQLErrors[0].message)
      }
      if(error.networkError) {
        this.notifyError(error.networkError)
      }
    }
  }
})

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    i18n,
    el: '[data-behavior="vue"]',
    pinia
  })
})
Vue.component('copy-to-clipboard', copyToClipboard)
Vue.component('local-date', date)
Vue.component('waiter', waiter)
Vue.component('qr-code', VueQRCodeComponent)
Vue.component('client-qr-scanner', clientQrScanner)
Vue.component('profile-cable-plug', profileCablePlug)
Vue.component('balance', balance)
Vue.component('balance-rewards', balanceRewards)
Vue.component('dob-picker', dobPicker)
Vue.component('auto-printer', autoPrinter)
Vue.component('progress-bar', progressBar)
Vue.component('notifications', notifications)
Vue.component('pay-fields', payFields)
Vue.component('pay-by-card', payByCard)
Vue.component('free-pay', freePaysNew)
Vue.component('free-pay-with-phone', freePaysNewWithPhone)
Vue.component('free-pay-with-email', freePaysNewWithEmail)
Vue.component('unverified-from-free-pay', unverifiedFromFreePay)
Vue.component('free-scanner', freeScanner)
Vue.component('tip-switch', tipSwitch)
Vue.component('tip-switch-for-pos', tipSwitchForPos)
Vue.component('tip-per-sub', tipPerSub)
Vue.component('tip-values', tipValues)
Vue.component('subacc-access-to-cof', subAccAccessToCof)
Vue.component('allow-manage-subaccounts', allowManageSubaccounts)
Vue.component('generic-flow-switch', updateGenericFlow)
Vue.component('gateway-switch', updateGatewaySwitch)
Vue.component('require-invoice-field-switch', updateRequireInvoiceField)
Vue.component('require-invoice-confirmation-switch', updateRequireInvoiceConfirmation)
Vue.component('refund-void-switch', refundVoidSwitch)
Vue.component('allow-emails', allowEmailReceipts)
Vue.component('allow-search-all-transactions', allowSearchThroughAllTransactions)
Vue.component('signature-pad-switch', showSignaturePadSwitch)
Vue.component('show-at-dash', showAtDash)
Vue.component('radar', radar)
Vue.component('payment-fee-settings', paymentFeeSettings)
Vue.component('blind-transaction-settings', blindTransactionSettings)
Vue.component('pay-by-bank-settings', payByBankSetting)
Vue.component('terminal-settings', terminalPaySetting)
Vue.component('terminal-enabled', terminalEnabledSetting)
Vue.component('withdraw-switch', withdrawSwitch)
Vue.component('auto-reload', autoReload)
Vue.component('required-secured-check', requireSecuredCheck)
Vue.component('feedback-hub-settings', feedbackHubSettings)
Vue.component('policy-url', policyUrl)
Vue.component('send-bill-note', sendBillNote)
Vue.component('notification-settings', notificationSettings)
Vue.component('subacc-history-limit', subaccHistoryLimit)
Vue.component('loyalty', loyalty)
Vue.component('loyalty-progress', loyaltyProgress)
Vue.component('loyalty-reward', loyaltyReward)
Vue.component('merchant-progress-bar', merchantProgressBar)
Vue.component('unverified-progress-bar', unverifiedProgressBar)
Vue.component('skip-this-step-wrapper', skipThisStepWrapper)
Vue.component('tax', tax)
Vue.component('reports', reports)
Vue.component('withdraw-balance', withdrawBalance)
Vue.component('withdraw-reward-balance', withdrawRewardBalance)
Vue.component('doing-business-as', doingBusinessAs)
Vue.component('add-money', addMoney)
Vue.component('kba', kba)
Vue.component('check-by-photo', checkByPhoto)
Vue.component('charge-ach-direct', chargeAchDirect)
Vue.component('stripe-balance', stripeBalance)
Vue.component('charge-generic-card', chargeGenericCard)
Vue.component('adyen-tos-viewer', adyenTosViewer)
Vue.component('disputes-badge', disputesBadge)
Vue.component('check-by-double-photo', checkByDoublePhoto)
Vue.component('new-payment-event', newPaymentEvent)
Vue.component('sub-acc-download-app', subAccDownloadApp)
Vue.component('charge-card-on-file', chargeCardOnFile)
Vue.component('today-stat', todayStat)
Vue.component('home-stat', homeStat)
Vue.component('show-check-images', showCheckImages)
Vue.component('show-signature', showSignature)
Vue.component('show-bad-check-images', showBadCheckImages)
Vue.component('bill-split', billSplitMain)
Vue.component('bill-split-new', billSplitNew)
Vue.component('bill-split-active', billSplitActive)
Vue.component('pay-bill-split', payBillSplit)
Vue.component('cancel-pending-button', cancelPendingTransaction)
Vue.component('devise-links-wrapper', deviseLinkWrapper)
Vue.component('gift-card-new-payment', giftCardPaymentNew)
Vue.component('emojify', emojify)
Vue.component('banner', banner)
Vue.component('send-check', sendCheck)
Vue.component('feedback-hub', feedbackHub)
Vue.component('verify-phone', verifyPhone)
Vue.component('verify-email', verifyEmail)
Vue.component('setup-unverified-profile', setupUnverifiedProfile)
Vue.component('setup-unverified-profile-by-button', setupUnverifiedProfileByButton)
Vue.component('vue-barcode', VueBarcode)
Vue.component('switch-to-ios-app', unverifiedProfileSwitchToIosApp)
Vue.component('my-qrs', myQrs)
Vue.component('master-report-download', adminMasterReportDownload)
Vue.component('routing-input', adminRoutingInput)
Vue.component('check-image-uploader', adminCheckImageUploader)
Vue.component('check-image-preview', adminCheckImagePreview)
Vue.component('add-ebt-card', addEbtCard)
Vue.component('ebt-balance', ebtBalance)
Vue.component('add-credit-card', addCreditCard)
Vue.component('pinpad-proxy', pinpadProxy)
Vue.component('funding-source-picker', fundingSourcePicker)
Vue.component('re-assign-qr', reAssignQR)
Vue.component('re-assign-qr-scanner', reAssignQrScanner)
Vue.component('re-assign-qr-base', reAssignQrBase)
Vue.component('manual-charge', manualCharge)
Vue.component('direct-charge', directCharge)
Vue.component('generic-direct-charge', genericDirectCharge)
Vue.component('generic-remote-charge', genericRemoteCharge)
Vue.component('terminal-charge', terminalCharge)
Vue.component('request-change-amount', requestChangeAmount)
Vue.component('resend-sms', resendSms)
Vue.component('override-button', overrideButton)
Vue.component('send-receipt-button', sendReceiptButton)
Vue.component('external-payment', externalPayment)
Vue.component('manual-payment', manualPayment)
Vue.component('reverse-button', reverseButton)
Vue.component('void-check-button', voidCheckButton)
Vue.component('sub-account-picker', sunAccountPicker)
Vue.component('add-items', addItems)
Vue.component('paypal-onboard', paypalOnboard)
Vue.component('card-on-file', createCardOnFile)
Vue.component('merchants-table', managerMerchants)
Vue.component('generic-progress-bar', genericProgressBar)
Vue.component('link-order', linkOrder)
Vue.component('merchant-link-order', merchantLinkOrder)
Vue.component('proof-of-service', merchantProofOfService)
Vue.component('link-check-create', merchantLinkCheckCreate)
Vue.component('link-check-change-state', merchantLinkCheckChangeState)
Vue.component('select-account', selectAccount)
Vue.component('check-by-image', checkByImage)

// Stimulus section
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("stimulus/controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

Vue.filter('truncate', function (text, length, suffix) {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
});
